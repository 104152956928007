<template>
    <div class="fixed right-3 bottom-3 group z-100 chatViaWhatsApp" id="chatViaWhatsApp">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-whatsapp/20 group-hover:hidden"></span>
      <a :href="whatsappLink" target="_blank" :title="$t('widgets.wp.buy-via-whatsapp')" class="bg-whatsapp h-12 w-12 group-hover:scale-110 text-white rounded-full flex items-center justify-center cursor-pointer gap-2.5 p-3 transition-all	ease-in-out duration-300">
        <WhatsAppIcon class="h-7 w-7" />
      </a>
    </div>
</template>

<script setup>
import WhatsAppIcon from "~/assets/icons/whatsapp.svg"
import collect from "collect.js";


const storeConfig = useStoreConfig()
const { whatsAppConfig } = storeToRefs(storeConfig)

const config = computed(() => whatsAppConfig.value?.config)
const phoneNumber = computed(() => collect(config.value).where('labelKey', '=', 'phoneNumber').pluck('value').first())

const msg = collect(config.value).where('labelKey', '=', 'messageContent').pluck('value').first();

const selfText = ref(getLocaleString(msg))
const message = computed({
  get() {
    return selfText.value;
  },
  set(val) {
    selfText.value = val;
  }
})

const productLink = useState('shareProductLink')
const whatsappLink = computed(() => {
    if (typeof productLink.value !== 'undefined') {
        return `https://api.whatsapp.com/send?phone=${unref(phoneNumber)}&text=${unref(message)} ${productLink.value}`
    } else {
        return `https://api.whatsapp.com/send?phone=${unref(phoneNumber)}&text=${unref(message)}`
    }
})

</script>

<style>
#chatViaWhatsApp {
  @apply sm:mb-auto mb-16 mr-2;
}
</style>